/**
 * --------------------------------------------------------------------------
 * Bootstrap (v5.2.3): util/setupModel.js
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 * --------------------------------------------------------------------------
 */

import { isElement, toType } from './index'
import Manipulator from '../dom/manipulator'

/**
 * Class definition
 */

class Config {
  // Getters
  static get Default() {
    return {}
  }

  static get DefaultType() {
    return {}
  }

  static get NAME() {
    throw new Error('You have to implement the static method "NAME", for each component!')
  }

  _getConfig(setupModel) {
    setupModel = this._mergeConfigObj(setupModel)
    setupModel = this._configAfterMerge(setupModel)
    this._typeCheckConfig(setupModel)
    return setupModel
  }

  _configAfterMerge(setupModel) {
    return setupModel
  }

  _mergeConfigObj(setupModel, element) {
    const jsonConfig = isElement(element) ? Manipulator.getDataAttribute(element, 'setupModel') : {} // try to parse

    return {
      ...this.constructor.Default,
      ...(typeof jsonConfig === 'object' ? jsonConfig : {}),
      ...(isElement(element) ? Manipulator.getDataAttributes(element) : {}),
      ...(typeof setupModel === 'object' ? setupModel : {})
    }
  }

  _typeCheckConfig(setupModel, configTypes = this.constructor.DefaultType) {
    for (const property of Object.keys(configTypes)) {
      const expectedTypes = configTypes[property]
      const value = setupModel[property]
      const valueType = isElement(value) ? 'element' : toType(value)

      if (!new RegExp(expectedTypes).test(valueType)) {
        throw new TypeError(
          `${this.constructor.NAME.toUpperCase()}: Option "${property}" provided type "${valueType}" but expected type "${expectedTypes}".`
        )
      }
    }
  }
}

export default Config
